<template>
  <div
    v-if="reservationSpace"
    class="card"
  >
    <div class="card-image">
      <figure class="image is-4by3 has-background-light">
        <img
          v-if="reservationSpace.SpaceImage"
          :src="
            reservationSpace.SpaceImage
              | getLocationImage(reservationSpace.LocationId, '640')
          "
        />
      </figure>
    </div>
    <div class="card-content">
      <div class="title is-4">
        {{ reservationSpace.SpaceName }}
      </div>
      <div>{{ reservationSpace.Seats }} {{$t('Components.Reservation.ModalChangeDate.Text_Seats')}}</div>
      <div>
        {{
          reservationSpace.StartDate
            | dateFormat(this.$i18n.locale, 'longDateFormat')
        }}
      </div>
      <div>
        <span>{{ reservationSpace.StartMinutes | minutesToTime }} -
          {{ reservationSpace.EndMinutes | minutesToTime }}</span>

        <transition
          name="fade"
          mode="out-in"
        >
        <div :key="reservationSpace.PricePerSeat" v-if="showSpacePrice">
          <span class="has-text-weight-bold">{{
           reservationSpace.PricePerSeat | toCurrency(reservationSpace.CurrencyIso)
          }}</span>
        </div>
        </transition>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'

export default {
  components: {},

  props: {
    reservationSpace: {
      type: Object,
      default: null,
    },

    showSpacePrice: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      initalPricePerSeat: null
    }
  },

  computed: {
  },

  created() { 
  },

  methods: {
  },
}
</script>